<script setup lang="ts">
import type { ProductsProps } from "./Products.props"

defineProps<ProductsProps>()

const emit =
  defineEmits<{
    (e: "onAddToCart", productName: string): void
    (e: "onClickShowResult"): void
  }>()

const clickShowResult = () => emit("onClickShowResult")

const handleAddToCartNotification = (productName: string) => {
  emit("onAddToCart", productName)
}

const searchInput = useState("SEARCH_STATE")
</script>

<template>
  <div class="search-products">
    <p class="pig-bold mb-3 hidden uppercase text-green-main md:block">
      {{ title }}
    </p>

    <UtilsMarkdown
      class="pig markdown__link-underlined mb-6"
      v-if="errorDescription"
      :content="errorDescription"
    />

    <div class="beaver mb-6 flex items-center gap-1" v-if="suggestion">
      <span>{{ $t("searchbar.maybeYouLookingFor") }}:</span>
      <UtilsMarkdown
        class="
          markdown__link-beaver-medium
          markdown__link-underlined
          markdown__link-green-main
        "
        :content="suggestion"
      />
    </div>

    <div class="md:grid md:grid-cols-8 md:gap-x-6 md:gap-y-3">
      <div
        class="mt-2 md:col-span-4 md:mt-0"
        v-for="card in cards"
        :key="card.productCode"
      >
        <GAListInstantSearchProducts :name="searchInput">
          <ProductTileOverallSearch
            @notification="handleAddToCartNotification(card.title)"
            v-bind="card"
            class="md:h-full"
            is-search-results
            :full-width="false"
          />
        </GAListInstantSearchProducts>
      </div>
      <div
        class="mt-4 h-full w-full md:col-span-4 md:col-start-5 md:mt-0 md:pt-6"
      >
        <UtilsButton
          v-if="moreProducts"
          class="
            md:custom-btn-pig-medium
            w-full
            [&_span.utils-markdown]:text-center [&_span]:whitespace-normal
          "
          v-bind="moreProducts"
          theme="outlined-green"
          order="last"
          @click="clickShowResult"
          stop
        >
          <template #icon
            ><UtilsIcon
              name="ArrowRight.svg"
              color="green-main"
              class="h-5 w-5"
          /></template>
        </UtilsButton>
      </div>
    </div>
  </div>
</template>
