<script setup lang="ts">
import type { SuggestionsProps } from "./Suggestions.props"

defineProps<SuggestionsProps>()

const emit =
  defineEmits<{
    (e: "click", value: string): void
  }>()
</script>

<template>
  <div class="search-suggestions">
    <p
      v-if="title"
      class="pig-bold mb-3 hidden uppercase text-green-main md:block"
    >
      {{ title }}
    </p>

    <div class="flex flex-wrap gap-x-2 gap-y-3">
      <UtilsButton
        class="custom-btn-beaver transform-gpu drop-shadow-01"
        v-for="item in suggestions"
        :key="item.text"
        :to="item.path"
        theme="contained-white"
        v-bind="item"
        @click="emit('click', item.text!)"
      />
    </div>
  </div>
</template>
